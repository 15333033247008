import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["endDateField",  "startDateField", "experienceTotal"]

    get startDateReturn() {
        return this.startDateFieldTarget
    }

    get endDateReturn() {
        return this.endDateFieldTarget
    }

    getDatesElements(element, selector) {
        return {
            yearSelect: element.querySelector(`#${selector}_1i`),
            monthSelect: element.querySelector(`#${selector}_2i`)
        }
    }

    experienceCalculate(){
        const labelSelectorStart = this.startDateReturn.querySelector('label').getAttribute('for')
        const labelSelectorEnd = this.endDateReturn.querySelector('label').getAttribute('for')
        const dataStartDate = this.getDatesElements(this.startDateReturn, labelSelectorStart)
        const dataEndDate = this.getDatesElements(this.endDateReturn, labelSelectorEnd)

        const yearStart = parseInt(dataStartDate.yearSelect.value, 10);
        const monthStart = parseInt(dataStartDate.monthSelect.value, 10);

        let yearEnd, monthEnd;
        
        const stillInProgressCheckbox = document.querySelector('[data-check-dates-experience-target="stillInProgress"]');
        
        if (stillInProgressCheckbox && stillInProgressCheckbox.checked) {
            const today = new Date();
            yearEnd = today.getFullYear();
            monthEnd = today.getMonth() + 1;
        } else {
            yearEnd = parseInt(dataEndDate.yearSelect.value, 10);
            monthEnd = parseInt(dataEndDate.monthSelect.value, 10);
        }

        if (isNaN(yearStart) || isNaN(monthStart) || isNaN(yearEnd) || isNaN(monthEnd)) {
            return;
        }
    
        const monthsDiff = (yearEnd - yearStart) * 12 + (monthEnd - monthStart) + 1;
        const formattedTime = this.formatMonthsToYearsAndMonths(monthsDiff);
    
        if (this.hasExperienceTotalTarget) {
            const translationTemplate = this.experienceTotalTarget.dataset.i18nTimeExperience;
            this.experienceTotalTarget.innerHTML = translationTemplate.replace("%{total}", formattedTime);
        }
      }

    formatMonthsToYearsAndMonths(totalMonths) {
        const years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;
    
        // Leer las traducciones desde los `data-attributes`
        const yearText = years === 1 ? this.experienceTotalTarget.dataset.i18nYear : this.experienceTotalTarget.dataset.i18nYears;
        const monthText = months === 1 ? this.experienceTotalTarget.dataset.i18nMonth : this.experienceTotalTarget.dataset.i18nMonths;
        const andText = this.experienceTotalTarget.dataset.i18nAnd;

         if (years > 0 && months > 0) {
            return `${years} ${yearText} ${andText} ${months} ${monthText}`;
        } else if (years > 0) {
            return `${years} ${yearText}`;
        } else {
            return `${months} ${monthText}`;
        }
      }

    validationMonthDate(event) {
        const element = event.target.id.includes('start') ? this.startDateReturn : this.endDateReturn
        const labelSelector = element.querySelector('label').getAttribute('for')
        const { yearSelect, monthSelect } = this.getDatesElements(element, labelSelector)

        if(event.target.id === `${labelSelector}_1i`){
            let optionsMonths = []
            let actualMonth = new Date().getMonth() + 1
            const validateYear = yearSelect.value === new Date().getFullYear().toString()
            const defaultOption = monthSelect.value <= actualMonth ? monthSelect.value : actualMonth
            optionsMonths = this.extractOptionsMap(monthSelect, actualMonth, validateYear)
            validateYear && defaultOption && optionsMonths[defaultOption].setAttribute('selected', "")
            monthSelect.innerHTML = ''
            monthSelect.append(...optionsMonths)
        }
    }

    extractOptionsMap(monthSelect, actualMonth, validateYear) {
        const monthSelectOptions = [...monthSelect.options]
        return monthSelectOptions.map(option => {
            const optionElement = document.createElement('option')
            if(option.value > actualMonth && validateYear){
                this.setBasicFields(option, optionElement, "")
                this.setAttributesField(false, optionElement, 'disabled', "")
            }else if(!option.value && option.textContent !== monthSelectOptions[0].textContent){
                this.setBasicFields(option, optionElement, ++actualMonth)
                optionElement.removeAttribute('disabled')
            }else{
                this.setBasicFields(option, optionElement, option.value)
                if(option.value == monthSelect.value) this.setAttributesField(false, optionElement, 'selected', "")
            }
            return optionElement
        })
    }

    setBasicFields(option, optionElement, value){
        this.setAttributesField(option, optionElement, 'value', value)
        optionElement.textContent = option.textContent
    }

    setAttributesField(option, optionElement, param, value){
        optionElement.setAttribute(param, value)
    }
}